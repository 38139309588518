export default function () {
  return new Promise((resolve, reject) => {
    if (navigator.userAgent.indexOf("Firefox") > 0) {
      const lastTop = document.querySelector("html").scrollTop;
      const scrollHandle = () => {
        const top = document.querySelector("html").scrollTop;
        if (top < lastTop / 2) {
          resolve();
          window.removeEventListener("scroll", scrollHandle);
        }
      };
      window.addEventListener("scroll", scrollHandle);
      window.scrollTo(0, 0);
    } else {
      resolve();
    }
  })
}