<template>
  <div class="index">
    <div class="index-top">
      <img src="../../static/img/index/pic_banner.webp" alt="" />
    </div>
    <div class="index-about">
      <div class="index-title">
        ABOUT
        <div class="index-title-small">会社案内</div>
      </div>
      <div class="index-about-box">
        <div class="index-about-box_smalltext">
          最高のサービスを世界に
        </div>
        <div class="index-about-box_bigtext">
          フロンティア精神と探求心を武器にイノベーションを起こしデジタルテクノロジーの未来を共に創る
        </div>
        <div class="index-about-box_bottombox">
          <div class="bottombox_left">
            <div class="bottombox_left_list" style="margin-top: 25px;">
              <div class="bottombox_left_list-item">
                <div class="bottombox_left_list-item-num">1000+</div>
                <div class="bottombox_left_list-item-text">お客様へのサービス</div>
              </div>
              <div class="bottombox_left_list-item">
                <div class="bottombox_left_list-item-num">300+</div>
                <div class="bottombox_left_list-item-text">パートナー企業</div>
              </div>
            </div>
            <div class="bottombox_left_list" style="margin-top: 65px;">
              <div class="bottombox_left_list-item">
                <div class="bottombox_left_list-item-num">50+</div>
                <div class="bottombox_left_list-item-text">ベテラン専門家</div>
              </div>
              <div class="bottombox_left_list-item">
                <div class="bottombox_left_list-item-num">10+</div>
                <div class="bottombox_left_list-item-text">業界経験年数</div>
              </div>
            </div>
            <img src="../../static/img/index/btn_more.svg" alt="" style="margin-top: 94px;">
          </div>
          <div class="bottombox_right">
            <img src="../../static/img/index/pic_map.webp" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="index-product">
      <div class="index-product-box">
        <div class="index-product-box_left">
          <div class="index-product-box_left-title">
            製品開発
          </div>
          <div class="index-product-box_left-text">
            オールシーン、マルチプロトコルデバイスの標準アクセスとスマートデバイス化
          </div>
        </div>
        <div class="index-product-box_right">
          <div class="product-item" v-for="item, index in productlist" :key="index">
            <div class="product-item-title">
              {{ item.title }}
            </div>
            <div class="product-item-content">
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index-Product">
      <div class="index-Product-box">
        <div class="index-title">
          PRODUCT
          <div class="index-title-small">製品案内</div>
        </div>
        <div class="index-Product-box_bottom">
          <div class="left-Product">
            <div class="left-Product-btnbox">
              <div class="left-Product-btnbox-lbtn">
                App
              </div>
              <div class="left-Product-btnbox-rbtn">
                ソフトウェア開発
              </div>
            </div>
            <div class="left-Product-title">
              コミュニケーションをより簡単に
            </div>
            <div class="left-Product-content">
              インスタントメッセンジャーアプリの紹介：ワークグループの作成機能をサポートワークグループ内では最大1000人の同時チャット、メッセージ送信が可能グループの作成、グループへの招待機能管理者によるグループのコントロール機能音声、ビデオ通話の開始
            </div>
            <div class="left-Product-button">
              ダウンロード
            </div>
          </div>
          <div class="right-Product">
            <img src="../../static/img/index/pic_pro_right.svg" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="index-news">
      <div class="index-news-box">
        <div class="index-title">
          NEWS
          <div class="index-title-small">ニュースルーム</div>
        </div>
        <div class="index-news-box_bottom">
          <div class="news_bottom-item" v-for="item, index in newslist" :key="index">
            <img :src="item.img" alt="">
            <div class="news_bottom-item-title">
              {{ item.title }}
            </div>
            <div class="news_bottom-item-content">
              {{ item.content }}
            </div>
            <div class="news_bottom-item-date">
              {{ item.date }}
            </div>
            <div class="news_bottom-item-detail">
              詳細を読む
            </div>
          </div>
        </div>
        <div class="index-news-box_more">
          クリックして詳細を表示
        </div>
      </div>
    </div>
    <div class="index-bottom">
      <div class="index-bottom-box">
        <div class="index-bottom-box-title">
          デジタルテクノロジーの未来を共に創る
        </div>
        <div class="index-bottom-box-text">
          専門的なソリューションと先進的な製品により、あらゆるビジネスニーズに応える
        </div>
        <div class="line"></div>
        <div class="index-bottom-box-hui">
          方魚宮株式会社
        </div>
        <div class="index-bottom-box-listbox">
          <div class="index-bottom-box-listbox-list">
            <div class="bottom-item">方魚宮とは？</div>
            <div class="bottom-item">お問い合わせ</div>
            <div class="bottom-item">提携を検討してくださっているお客様へ</div>
          </div>
          <div class="index-bottom-box-listbox-list">
            <div class="bottom-item">ご意見箱</div>
            <div class="bottom-item">プライバシーポリシー</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routeScroll from "../../utils/routeScroll";
export default {
  name: "index",
  data() {
    return {
      newslist: [
        { date: "2020-11-23 14:36", content: "「ライブラリロック」インスタンスでの構築：需要分析からシナリオ決定まで", img: require('../../static/img/index/onenew.webp'), title: 'AR/VR 徹底比較：本当に将来性があるのはどちら？ ' },
        {
          date: "2020-11-23 14:36",
          content: "自然言語処理分野学会の最高峰、EMNLP2022がオンラインで開催。EMNLPは国際計算言語学会（ACL）により",
          img: require('../../static/img/index/twonew.webp'),
          title: '「ライブラリロック」インスタンスでの構築：需要分析からシナリオ…'
        },
        { date: "2020-11-23 14:36", content: "自然言語処理分野学会の最高峰、EMNLP2022がオンラインで開催。EMNLPは国際計算言語学会（ACL）により", img: require('../../static/img/index/threenew.webp'), title: '業界初、baidu主催の同時通訳ロボット講習会開催　機械同時通訳技術の進化について語る' },
      ],
      pre: 1,
      num: ["01", "02", "03"],
      productlist: [
        {
          title: 'webアプリ',
          content: 'React,Vue,Angular等の技術を使用。リアクティブで直感的、高い拡張性を兼ね備えたwebアプリ開発。ダイレクティブプログレッシブwebアプリとしてオンラインオフライン双方での動作も可能。'
        },
        {
          title: 'モバイルアプリ',
          content: 'swift,Javaを用いたAndroidおよびiosプラットフォーム向けネイティブモバイルアプリの開発。FlutterとReact Native等の技術を用いたクロスプラットフォームモバイルアプリの開発も可能。'
        },
        {
          title: 'ソフトウェアのオーダーメイド',
          content: '多岐に渡るカスタムソフトウェア開発サービスを提供。企業のwebアプリ、モバイルアプリ、IoTソリューションによるプロセスオートメーションをサポートする。'
        },
        {
          title: 'IoT',
          content: 'MQTT、firebase等によるマシンツーマシン（M2M）通信可能なIoTソリューションとスマートデバイスによりリアルタイムデータ伝送を実現。'
        },
        {
          title: '設備開発',
          content: 'モジュールやSDK開発により統合されたユニファイドSDKを提供。設備側の機能を開発のみでクイックアクセスの実現が可能に。'
        },
        {
          title: 'ソフトウェアの公開、量産',
          content: '開発完了後、製品構成を指定した大量生産が可能。製品認証、テスト、アプリストアでの公開まで一貫したサポートを提供。'
        }
      ]
    };
  },

  methods: {

    change(pre, next) {
      this.pre = pre + 1;
      console.log(this.pre);
    },
    toproduct() {
      routeScroll().then(() => {
        this.$router.push({
          path: "/product/product",
        });
      });
    },
    toabout() {
      routeScroll().then(() => {
        this.$router.push({
          path: "/about/about",
        });
      })

    },
  },
};
</script>

<style lang="less" scoped>
.dark_anchor {
  padding-top: 73px;
  /* 73px是导航栏高度 */
  margin-top: -73px;
}

.index {
  width: 100%;

  &-top {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &-about {
    width: 1400px;
    margin: auto;
    padding-top: 120px;
    height: 830px;
    padding-bottom: 155px;

    .index-title {
      font-size: 88px;
      font-family: DINPro-Bold, DINPro;
      font-weight: bold;
      color: #e8edf8;
      margin: auto;
      width: 320px;
      text-align: center;
      position: relative;
      line-height: 64px;

      .index-title-small {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: 21px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-size: 44px;
        font-weight: 600;
        color: #252b3a;
        width: 176px;
      }
    }

    .index-about-box {
      margin-top: 151px;
      width: 100%;
      height: 600px;

      &_smalltext {
        color: #3070EF;
        font-size: 15px;
        font-weight: 600;
        font-family: PingFangSC-Semibold;
      }

      &_bigtext {
        width: 560px;
        font-size: 24px;
        line-height: 40px;
        font-family: MidashiGoPro-MB31;
        color: #252B3A;
        font-weight: 600;
        margin-top: 15px;
      }

      &_bottombox {
        width: 100%;
        height: 447px;
        margin-top: 50px;
        display: flex;

        .bottombox_left {
          width: 385px;
          height: 100%;

          &_list {
            width: 100%;
            display: flex;
            justify-content: space-between;

            &-item {
              &-num {
                width: 150px;
                font-size: 56px;
                font-family: DINPro-Medium;
                color: #3070EF;
              }

              &-text {
                font-size: 14px;
                font-family: PingFangSC-Regular;
              }
            }
          }
        }

        .bottombox_right {
          margin-left: 130px;
          width: 940px;
          height: 447px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  &-product {
    width: 100%;
    height: 604px;
    background-color: rgba(245, 247, 250, 1);
    display: flex;
    align-items: center;
    justify-content: center;

    &-box {
      width: 1400px;
      height: 384px;
      display: flex;

      &_left {
        width: 300px;
        height: 384px;
        background: url(../../static/img/index/leftpro.svg) no-repeat center;
        color: #fff;

        &-title {
          padding-top: 60px;
          margin-left: 30px;
          font-size: 19px;
          font-family: PingFangSC-Semibold;
        }

        &-text {
          opacity: 0.7;
          font-size: 15px;
          width: 240px;
          font-family: PingFangSC-Regular;
          padding-top: 15px;
          margin-left: 30px;
        }
      }

      &_right {
        width: 1081px;
        height: 100%;
        margin-left: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .product-item {
          background: url(../../static/img/index/pic_pro_default.svg) no-repeat center;
          border-radius: 4px;
          margin-bottom: 14px;
          width: 351px;
          height: 185px;
          cursor: pointer;

          &-title {
            font-size: 19px;
            font-family: PingFangSC-Semibold;
            color: #252B3A;
            margin-left: 30px;
            padding-top: 20px;
            font-weight: 600;
          }

          &-content {
            font-size: 15px;
            color: #707991;
            margin-left: 30px;
            padding-top: 15px;
            width: 291px;
            line-height: 24px;
          }
        }

        .product-item:hover {
          background: url(../../static/img/index/pic_pro_hover.svg) no-repeat center;

          div {
            color: #fff;
          }
        }
      }
    }
  }

  &-Product {
    width: 100%;
    height: 1140px;
    background: url(../../static/img/index/pic_bg_pro.webp) no-repeat center;
    background-size: cover;

    &-box {
      width: 1400px;
      height: 100%;
      margin: auto;
      padding-top: 160px;

      .index-title {
        font-size: 88px;
        font-family: DINPro-Bold, DINPro;
        font-weight: bold;
        color: #e8edf8;
        margin: auto;
        width: 420px;
        text-align: center;
        position: relative;
        line-height: 64px;

        .index-title-small {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          top: 21px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-size: 44px;
          font-weight: 600;
          color: #252b3a;
          width: 176px;
        }
      }

      &_bottom {
        height: 760px;
        width: 100%;
        margin-top: 150px;
        display: flex;
        justify-content: space-between;

        .left-Product {
          width: 542px;
          height: 100%;

          &-btnbox {
            width: 297px;
            height: 38px;
            margin-top: 10px;
            border-radius: 34px;
            display: flex;
            background-color: #f9f9f9;
            font-weight: 600;

            &-lbtn {
              width: 88px;
              height: 38px;
              border-radius: 25px;
              background-color: rgba(48, 112, 239, 1);
              color: #fff;
              font-size: 16px;
              line-height: 38px;
              text-align: center;
            }

            &-rbtn {
              border-radius: 25px;
              width: 200px;
              height: 38px;
              color: #252B3A;
              line-height: 38px;
              text-align: center;
            }
          }

          &-title {
            font-weight: 800;
            margin-top: 52px;
            font-size: 44px;
            font-family: PingFangSC-Semibold;
            color: #252B3A;
          }

          &-content {
            margin-top: 36px;
            width: 542px;
            line-height: 30px;
            color: rgba(37, 43, 58, 1);
            font-size: 14px;
            font-family: PingFangSC-Regular;
            font-weight: 600;
          }

          &-button {
            margin-top: 60px;
            width: 160px;
            height: 50px;
            border-radius: 25px;
            background-color: #3595FF;
            box-shadow: 0px 22px 44px 0px rgba(25, 83, 189, 0.05);
            text-align: center;
            line-height: 50px;
            font-size: 18px;
            color: #fff;
            font-weight: 600;
          }
        }

        .right-Product {
          width: 830px;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

  }

  &-news {
    width: 100%;
    height: 1000px;
    background: url(../../static/img/index/pic_bg_new.webp) no-repeat center;
    background-size: cover;

    &-box {
      width: 1188px;
      height: 100%;
      margin: auto;
      padding-top: 140px;

      .index-title {
        font-size: 88px;
        font-family: DINPro-Bold, DINPro;
        font-weight: bold;
        color: #e8edf8;
        margin: auto;
        width: 270px;
        text-align: center;
        position: relative;
        line-height: 64px;

        .index-title-small {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          top: 21px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-size: 44px;
          font-weight: 600;
          color: #252b3a;
          width: 176px;
        }
      }

      &_bottom {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 500px;
        margin-top: 120px;

        .news_bottom-item {
          width: 356px;

          &-title {
            color: #000;
            font-size: 21px;
            width: 356px;
            font-family: PingFangSC-Regular;
            line-height: 30px;
            margin-top: 33px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          &-content {
            color: #000;
            font-size: 15px;
            width: 356px;
            font-family: PingFangSC-Regular;
            line-height: 20px;
            margin-top: 14px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          &-date {
            color: #999;
            font-size: 14px;
            width: 356px;
            font-family: PingFangSC-Regular;
            margin-top: 18px;
          }

          &-detail {
            color: #3070EF;
            font-size: 17px;
            width: 356px;
            font-family: PingFangSC-Regular;
            margin-top: 24px;
          }
        }
      }

      &_more {
        margin-top: 80px;
        color: rgba(56, 117, 239, 1);
        font-size: 16px;
        text-align: center;
      }
    }
  }

  &-bottom {
    background: url(../../static/img/index/pic_footer_bg.webp) no-repeat center;
    background-size: cover;
    width: 100%;
    height: 418px;
    padding-top: 82px;

    &-box {
      width: 675px;
      margin-left: 10%;

      &-title {
        color: rgba(37, 43, 58, 1);
        font-size: 40px;
        font-family: PingFangSC-Semibold;
      }

      &-text {
        margin-top: 16px;
        color: rgba(37, 43, 58, 1);
        font-size: 14px;
        font-family: PingFangSC-Regular;
      }

      .line {
        margin-top: 50px;
        width: 100%;
        height: 1px;
        background-color: #CBD3DF;
      }

      &-hui {
        margin-top: 45px;
        color: rgba(37, 43, 58, 1);
        font-size: 18px;
        font-family: PingFangSC-Semibold;
        font-weight: 800;
      }

      &-listbox {
        width: 250px;
        height: 200px;
        margin-top: 22px;
        display: flex;
        justify-content: space-between;
        &-list {
          height: 100%;
          width: 100px;

          .bottom-item {
            color: rgba(37, 43, 58, 1);
            font-size: 15px;
            margin-bottom: 10px;

          }
        }
      }
    }
  }
}</style>
